<template>
  <be-dropdown variant="light" class="mr-md-5 mb-2 mb-md-0" size="sm">
    <template #button-content>
      <span class="text-primary">
        {{ title(modelValue) }}
      </span>
    </template>

    <template v-for="(subtitleGroup, index) in availableSubtitles">
      <be-dropdown-divider v-if="index > 1" :key="index" class="my-0" />

      <be-dropdown-item
        v-for="subtitleOption in subtitleGroup"
        :key="subtitleOption.key"
        :active="subtitleOption.key === modelValue"
        @click="$emit('update:modelValue', subtitleOption.key)"
      >
        {{ subtitleOption.text }}
      </be-dropdown-item>
    </template>
  </be-dropdown>
</template>

<script>
import { compareText } from "@/utils/text-utils";

export default {
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["update:modelValue"],

  computed: {
    availableSubtitles() {
      let subtitles = {
        1: [
          {
            key: null,
            text: this.$t("models.item.subtitles.no_subtitle"),
          },
        ],

        2: [
          {
            key: "decision",
            text: this.$t("models.item.subtitles.decision"),
          },
          {
            key: "discussion",
            text: this.$t("models.item.subtitles.discussion"),
          },
          {
            key: "formality",
            text: this.$t("models.item.subtitles.formality"),
          },
          {
            key: "information",
            text: this.$t("models.item.subtitles.information"),
          },
        ],

        3: this.activeFollowUps,
      };

      Object.keys(subtitles).forEach((group) => {
        subtitles[group] = subtitles[group].sort((a, b) =>
          compareText(a, b, "text")
        );
      });

      return subtitles;
    },

    activeFollowUps() {
      const results = [];

      if (this.platformFeature("inquiries")) {
        results.push({
          key: "follow_up_inquiries",
          text: this.$t("models.item.subtitles.follow_up_inquiries"),
        });
      }

      results.push({
        key: "follow_up_reports",
        text: this.$t("models.item.subtitles.follow_up_reports"),
      });

      results.push({
        key: "follow_up_decisions",
        text: this.$t("models.item.subtitles.follow_up_decisions"),
      });

      results.push({
        key: "follow_up_meetings",
        text: this.$t("models.item.subtitles.follow_up_meetings"),
      });

      results.push({
        key: "follow_up_tasks",
        text: this.$t("models.item.subtitles.follow_up_tasks"),
      });

      return results;
    },
  },

  methods: {
    title(key) {
      if (key) {
        return this.$t(`models.item.subtitles.${key}`);
      } else {
        return this.$t("models.item.subtitles.no_subtitle");
      }
    },
  },
};
</script>
